<template>
    <div class="input-grid">
        <label class="label has-text-light">{{ label }}</label>
        <input
            v-for="(frame, i) in myKeyframes"
            class="input is-small is-static has-text-primary"
            :key="id + i"
            :value="getValue(frame)"
            :step="step"
            :min="min"
            :max="max"
            type="number"
            @focus="setFocus(i)"
            @change="updateLayers($event.target.value)"
        />
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
    name: "EditInputGrid",
    props: ["label", "id", "step", "min", "max", "editor"],
    emits: ["focus"],

    data() {
        return {};
    },
    computed: {
        ...mapGetters(["currentKeyframe", "maxKeyframes"]),
        myKeyframes() {
            const layer = 0; // show layer 0 values only, for now
            const frames = this.editor.selectedKeyframes[layer];
            return frames;
        },
    },
    methods: {
        ...mapMutations(["updateLayerKeyframe"]),

        getValue(frame) {
            let val = frame[this.id];
            return val;
        },
        setFocus(i) {
            this.$emit("focus", i);
        },
        updateLayers(val) {
            // update all selected layers
            // const keyframeCount = this.maxKeyframes;
            for (const layer of this.editor.selectedLayers) {
                // run twice if keyframe locked
                for (let i = 0; i <= this.maxKeyframes - 1; i++) {
                    if (
                        this.editor.keyframeLock ||
                        this.currentKeyframe === i
                    ) {
                        let data = {
                            layer: layer,
                            keyframe: i,
                            id: this.id,
                            val: val,
                        };
                        this.updateLayerKeyframe(data);
                    }
                }
            }
        },
    },
};
</script>
