<template>
    <div id="editor">
        <!-- LEFT Panel -->
        <div class="left-side">
            <div class="field is-expanded">
                <label
                    class="label has-text-light"
                    title="Seperate dialog with line breaks.&#10;Shortcodes:&#10;~1 prefix = 1 sec delay&#10;^ prefix = text at top&#10;#FFFF00 prefix = text color"
                    >Dialog
                </label>
                <textarea
                    v-model.lazy="dialogInput"
                    v-debounce="1000"
                    class="dialog-input textarea is-small"
                    placeholder="Seperate using line breaks"
                ></textarea>
            </div>

            <div v-if="layers.length > 0">
                <label class="label has-text-light">Layers</label>
                <div class="select is-multiple is-expanded is-small">
                    <select
                        class="layer-select"
                        v-model="selectedLayers"
                        multiple
                    >
                        <option
                            v-for="(pict, index) in layers.slice().reverse()"
                            :key="index + pict.name"
                            :value="layers.length - index - 1"
                        >
                            {{ pict.name }}
                        </option>
                    </select>
                </div>
                <div
                    v-if="selectedLayers.length > 0"
                    class="buttons are-small has-addons is-right"
                >
                    <button
                        title="Shuffle forward"
                        @click="moveLayerUp"
                        class="button is-dark"
                    >
                        <span class="icon">
                            <i
                                class="
                                    fa-solid
                                    fa-arrow-down-wide-short
                                    fa-flip-vertical
                                "
                            ></i>
                        </span>
                    </button>
                    <button
                        title="Shuffle backwards"
                        @click="moveLayerDown"
                        class="button is-dark"
                    >
                        <span class="icon">
                            <i class="fa-solid fa-arrow-down-wide-short"></i>
                        </span>
                    </button>
                    <button
                        :title="
                            selectedLayers.length === 1
                                ? 'Replace image'
                                : 'Replace image (select 1 layer only)'
                        "
                        class="button is-dark"
                        :disabled="selectedLayers.length > 1"
                    >
                        <span class="icon">
                            <i class="fa-solid fa-file-import"></i>
                        </span>
                    </button>
                    <button
                        title="Delete selected layer(s)"
                        @click="deleteLayer"
                        class="button is-dark has-text-danger"
                    >
                        <span class="icon">
                            <i class="fa-solid fa-trash-can"></i>
                        </span>
                    </button>
                    <button
                        title="Deselect all"
                        @click="selectedLayers = []"
                        class="button is-dark is-expanded"
                    >
                        <span class="icon">
                            <i class="fa-solid fa-xmark"></i>
                        </span>
                    </button>
                </div>
            </div>
            <button
                title="Import images"
                @click="$refs.importFile.click()"
                class="button is-dark is-small my-2"
                :class="{ 'is-warning': layers.length < 1 }"
            >
                <span class="icon">
                    <i class="fa-solid fa-images"></i>
                </span>
                <span> Import images</span>
            </button>
            <input
                ref="importFile"
                type="file"
                hidden
                multiple
                @change="importLocalFiles"
            />
            <div class="field is-expanded">
                <label
                    class="label has-text-light"
                    title="Seperate dialog with line breaks.&#10;Shortcodes:&#10;~1 prefix = 1 sec delay&#10;^ prefix = text at top&#10;#FFFF00 prefix = text color"
                    >Transcript
                </label>
                <textarea
                    class="dialog-input textarea is-small"
                    placeholder="has the scene changed, how are characters dressed, their pose or action, any meaningful objects"
                ></textarea>
            </div>
        </div>

        <!-- MAIN Area -->
        <main>
            <!-- Top bar -->
            <div class="toolbar toolbar-top">
                <label class="checkbox has-text-light">
                    <input type="checkbox" />
                    New scene
                </label>
                <div class="buttons are-small">
                    <button
                        title="Insert blank panel before"
                        @click="insertPanelBefore"
                        class="button is-dark"
                    >
                        <span class="icon">
                            <i class="fa-solid fa-caret-left"></i>
                            <i class="fa-solid fa-circle-plus"></i>
                        </span>
                    </button>
                    <button
                        title="Duplicate this panel before"
                        @click="duplicatePanelBefore"
                        class="button is-dark"
                    >
                        <span class="icon">
                            <i class="fa-solid fa-caret-left"></i>
                            <i class="fa-solid fa-images"></i>
                        </span>
                    </button>
                    <button
                        title="Delete this panel"
                        @click="deleteCurrentPanel"
                        class="button is-dark has-text-danger"
                    >
                        <span class="icon">
                            <i class="fa-solid fa-trash-can"></i>
                        </span>
                    </button>
                    <button
                        title="Duplicate this panel after"
                        @click="duplictePanelAfter"
                        class="button is-dark"
                    >
                        <span class="icon">
                            <i class="fa-solid fa-images"></i>
                            <i class="fa-solid fa-caret-right"></i>
                        </span>
                    </button>
                    <button
                        title="Insert blank panel after"
                        @click="insertPanelAfter"
                        class="button is-dark"
                    >
                        <span class="icon">
                            <i class="fa-solid fa-circle-plus"></i>
                            <i class="fa-solid fa-caret-right"></i>
                        </span>
                    </button>
                </div>

                <div class="field is-grouped">
                    <div class="control">
                        <button
                            title="Start new episode"
                            @click="createNewEpisode"
                            class="button is-small is-dark"
                        >
                            <span>New Episode</span>
                        </button>
                    </div>
                    <div class="control">
                        <div class="file is-dark">
                            <label class="file-label">
                                <input
                                    class="file-input"
                                    type="file"
                                    name="load"
                                    accept="application/json"
                                    id="file-input"
                                    @change="importEpisodeFile"
                                />
                                <span class="file-cta">
                                    <span class="file-label"> Load </span>
                                </span>
                            </label>
                        </div>
                    </div>
                    <div class="control">
                        <button
                            title="Save episode"
                            @click="saveEpisodeFile"
                            class="button is-small is-dark"
                            :class="{ 'is-danger': !saved }"
                        >
                            <span>Save</span>
                        </button>
                    </div>
                </div>
            </div>

            <!-- Comic Preview -->
            <div v-if="loading" class="is-ghost">Load an episode</div>
            <!-- :key="panelHackCounter" -->
            <comic-player
                v-else
                ref="ComicPlayer"
                :playLoopMode="playLoopMode"
                :class="'keyframe' + currentKeyframe"
                @stop="playerPause"
                @select="clickComicLayer"
                @deselect="selectedLayers = []"
            />

            <!-- Bottom bar -->
            <div class="toolbar toolbar-bottom">
                <p>
                    {{ timecode(panelDuration) }} / Panel
                    <strong>{{ currentPanel + 1 }}</strong>
                    <small> of {{ actualPanelCount }} (locked to {{ episode.panelCount }})</small>
                </p>
                <div class="buttons">
                    <button
                        @click="clickFirstPanel"
                        :disabled="disableFirstPanel"
                        class="button is-dark is-small"
                        title="Previous scene"
                    >
                        <span class="icon">
                            <i class="fa-solid fa-backward-fast"></i>
                        </span>
                    </button>
                    <button
                        @click="clickPrevPanel"
                        :disabled="disablePrevPanel"
                        class="button is-dark is-small"
                        title="Previous panel"
                    >
                        <span class="icon">
                            <i class="fa-solid fa-backward-step"></i>
                        </span>
                    </button>
                    <button
                        title="Stop"
                        v-if="playing"
                        @click="editorPause"
                        class="button is-dark has-text-info"
                    >
                        <span class="icon">
                            <i class="fa-solid fa-stop"></i>
                        </span>
                    </button>
                    <button
                        title="Play"
                        v-else
                        @click="play"
                        class="button is-dark has-text-info"
                    >
                        <span class="icon">
                            <i class="fa-solid fa-play"></i>
                        </span>
                    </button>
                    <button
                        @click="clickNextPanel"
                        :disabled="disableNextPanel"
                        class="button is-dark is-small"
                        title="Next panel"
                    >
                        <span class="icon"
                            ><i class="fa-solid fa-forward-step"></i>
                        </span>
                    </button>
                    <button
                        @click="clickLastPanel"
                        :disabled="disableLastPanel"
                        class="button is-dark is-small"
                        title="Next scene"
                    >
                        <span class="icon">
                            <i class="fa-solid fa-forward-fast"></i>
                        </span>
                    </button>
                    <button
                        :title="'Loop ' + playLoopMode"
                        @click="togglePlayLoopMode"
                        class="button is-dark is-small has-text-info"
                    >
                        <span class="icon">
                            <i class="fa-solid" :class="playLoopModeClass"></i>
                        </span>
                    </button>
                </div>
                <p>
                    <!-- Scene {{ currentScene + 1 }} of X / 3:33 <br /> -->
                    {{ timecode(episodeDuration) }} / Episode
                    <strong>#{{ episode.number }}</strong>
                    <small> of {{ episodeList.length }}</small>
                </p>
            </div>
        </main>

        <!-- RIGHT Panel -->
        <div class="right-side">
            <div class="input-grid">
                <button
                    @click="keyframeLock = !keyframeLock"
                    title="sync-lock keyframes"
                    class="button is-small"
                    :class="keyframeLock ? 'is-danger' : 'is-dark'"
                >
                    <span v-if="!keyframeLock">keyframes</span>
                    <span v-else class="icon">
                        <i
                            class="fa-solid"
                            :class="keyframeLock ? 'fa-lock' : 'fa-unlock'"
                        ></i>
                    </span>
                </button>
                <button
                    @click="goFirstKeyframe"
                    class="button is-small"
                    :class="currentKeyframe === 0 ? 'is-info' : 'is-dark'"
                >
                    Start
                </button>
                <button
                    @click="goLastKeyframe"
                    @dblclick="copyStartFrameToEnd"
                    class="button is-small"
                    :class="currentKeyframe === 1 ? 'is-info' : 'is-dark'"
                    title="Double-click to copy start values"
                >
                    End
                </button>
            </div>
            <div v-if="selectedLayers.length === 0" class="mt-5">
                <p class="has-text-weight-bold">Panel properties</p>
                <hr />

                <div class="input-grid" style="--grid-widths: 66% 1fr">
                    <label
                        title="Reading time overrides this setting"
                        class="label has-text-light"
                    >
                        Min Panel Duration
                    </label>
                    <input
                        :value="minPanelDuration"
                        @change="changeMinDuration"
                        class="input is-small is-static has-text-primary"
                        type="number"
                        step="1"
                        min="0"
                    />
                </div>
                <div class="field">
                    <label class="label has-text-light">Transition In</label>
                    <div class="control">
                        <div class="select is-small is-expanded">
                            <!-- https://www.transition.style/ -->
                            <select
                                @change="setTransitionType"
                                :value="transitionType"
                            >
                                <option value="none">None (cut)</option>
                                <optgroup label="Fade">
                                    <option value="fade">Cross-fade</option>
                                    <option value="fadeToWhite">
                                        Fade to white
                                    </option>
                                    <option value="fadeToBlack">
                                        Fade to black
                                    </option>
                                </optgroup>
                                <optgroup label="Zoom">
                                    <option value="zoomIn">Zoom in</option>
                                    <option value="zoomOut">Zoom out</option>
                                </optgroup>
                                <optgroup label="Wipe">
                                    <option value="wipeLeft">Wipe Left</option>
                                    <option value="wipeRight">
                                        Wipe Right
                                    </option>
                                    <option value="wipeUp">Wipe Up</option>
                                    <option value="wipeDown">Wipe Down</option>
                                </optgroup>
                                <optgroup label="Push">
                                    <option value="pushLeft">Push Left</option>
                                    <option value="pushRight">
                                        Push Right
                                    </option>
                                    <option value="pushUp">Push Up</option>
                                    <option value="pushDown">Push Down</option>
                                </optgroup>
                                <!-- <optgroup label="Whip Pan">
                                    <option value="whipLeft">
                                        Whip Pan left
                                    </option>
                                    <option value="whipRight">
                                        Whip Pan right
                                    </option>
                                </optgroup> -->
                            </select>
                        </div>
                    </div>
                </div>
                <div class="input-grid" style="--grid-widths: 66% 1fr">
                    <label title="Seconds" class="label has-text-light">
                        Transition Duration
                    </label>
                    <input
                        @change="setTransitionDuration"
                        :value="transitionDuration"
                        class="input is-small is-static has-text-primary"
                        type="number"
                        step="0.25"
                        min="0"
                    />
                </div>

                <p class="mt-6 has-text-weight-bold">Episode properties</p>
                <hr />

                <div class="input-grid" style="--grid-widths: 66% 1fr">
                    <label
                        title="Reading time overrides this setting"
                        class="label has-text-light"
                    >
                        Number
                    </label>
                    <input
                        v-model="episode.number"
                        class="input is-small is-static has-text-primary"
                        type="number"
                        step="1"
                        min="0"
                    />
                </div>
                <div class="field">
                    <label class="label has-text-light">Title</label>
                    <div class="control">
                        <input
                            v-model="episode.title"
                            class="input is-small"
                            type="text"
                        />
                    </div>
                </div>
                <div class="field">
                    <label class="label has-text-light">Description</label>
                    <div class="control">
                        <textarea
                            class="textarea is-small"
                            v-model="episode.description"
                        />
                        <!-- <input v-model="episode.description"
                               class="input is-small"
                               type="text" /> -->
                    </div>
                </div>
            </div>
            <div v-if="selectedLayers.length > 0" class="mt-5">
                <p
                    v-for="layer in selectedLayers"
                    :key="layer"
                    class="has-text-weight-bold"
                >
                    <i class="fa-solid fa-image"></i> {{ layers[layer].name }}
                </p>
                <hr />
                <edit-input-grid
                    :id="'x'"
                    :label="'Horz (x)'"
                    :step="20"
                    :min="null"
                    :max="null"
                    :editor="editorState"
                    @focus="(n) => setCurrentKeyframe(n)"
                />
                <edit-input-grid
                    :id="'y'"
                    :label="'Vert (y)'"
                    :step="20"
                    :min="null"
                    :max="null"
                    :editor="editorState"
                    @focus="(n) => setCurrentKeyframe(n)"
                />
                <edit-input-grid
                    :id="'scale'"
                    :label="'Scale'"
                    :step="0.1"
                    :min="0"
                    :max="null"
                    :editor="editorState"
                    @focus="(n) => setCurrentKeyframe(n)"
                />
                <edit-input-grid
                    :id="'blur'"
                    :label="'Blur'"
                    :step="2"
                    :min="0"
                    :max="null"
                    :editor="editorState"
                    @focus="(n) => setCurrentKeyframe(n)"
                />
                <edit-input-grid
                    :id="'opacity'"
                    :label="'Opacity'"
                    :step="0.25"
                    :min="0"
                    :max="1"
                    :editor="editorState"
                    @focus="(n) => setCurrentKeyframe(n)"
                />
                <edit-input-grid
                    :id="'rotate'"
                    :label="'Rotate'"
                    :step="22.5"
                    :min="null"
                    :max="null"
                    :editor="editorState"
                    @focus="(n) => setCurrentKeyframe(n)"
                />
                <edit-input-grid
                    :id="'delay'"
                    :label="'Delay (%)'"
                    :step="10"
                    :min="0"
                    :max="100"
                    :editor="editorState"
                    @focus="(n) => setCurrentKeyframe(n)"
                />
                <hr />
                <label class="label has-text-light">Align layer</label>
                <alignment-grid @align="alignLayer" />
                <div class="buttons">
                    <button class="button is-dark is-small">Reset all</button>
                </div>

                <div class="columns is-multiline is-mobile is-centered">
                    <div
                        v-for="index in selectedLayers.slice().reverse()"
                        :key="index"
                        class="column is-one-third has-text-centered"
                    >
                        <img
                            :src="
                                '/episodes/' +
                                episode.number +
                                '/' +
                                layers[index].name
                            "
                            style="max-height: 50px"
                        />
                    </div>
                </div>
            </div>
        </div>

        <!-- MODALS -->
        <!-- See commented code at end of file -->
    </div>
</template>

<script>
import ComicPlayer from "@/components/ComicPlayer.vue";
import AlignmentGrid from "@/components/AlignmentGrid.vue";
import EditInputGrid from "@/components/EditInputGrid.vue";
import { mapGetters, mapMutations } from "vuex";

// TODO: Load episode file
// Parts:
// - Nav: play/stop, prev/next panel, jump to panel/scene
// - auto-save, or confirm exit w/out saving
// - Options: (same as website)

export default {
    name: "Editor",
    components: {
        ComicPlayer,
        AlignmentGrid,
        EditInputGrid,
    },
    data() {
        return {
            // loading: true,
            // panelHackCounter: 0,
            readingWordsPerMin: 12, // characters per second
            minReadTime: 1.5, // seconds on screen

            playLoopMode: "episode",
            playLoopModeClass: "fa-arrows-spin",
            stage: { width: 1366, height: 768 },
            rememberEditorFrame: 0,
            keyframeLock: false,
            selectedLayers: [],
            dialogInput: "",
            blankPanel: { layers: [], dialog: [], minDuration: 1 },
        };
    },
    created() {
        window.addEventListener("keydown", this.onKeyPress);
    },
    beforeUnmount() {
        window.removeEventListener("keydown", this.onKeyPress);
    },
    mounted() {
        // document.body.classList.remove("scanlines");
        this.initEditor();
    },
    computed: {
        ...mapGetters([
            "episodeList",
            "episode",
            "currentPanel",
            "currentScene",
            "currentKeyframe",
            "editMode",
            "saved",
        ]),
        loading() {
            if (this.episode) return false;
            return true;
        },
        playing() {
            return !this.editMode;
        },
        layers() {
            if (
                this.episode.data &&
                this.episode.data.length > 0 &&
                this.currentPanel
            ) {
                const i = this.currentPanel;
                return this.episode.data[i].layers;
            }
            return [];
        },
        actualPanelCount() {
            return this.episode.data.length;
        },
        editorState() {
            // passed to input grid component
            // and used to center selected layer
            let frames = [];
            if (this.selectedLayers.length > 0) {
                for (const i of this.selectedLayers) {
                    frames.push(this.layers[i].frame);
                }
                // console.log("selectedKeyframes", frames);
            }
            return {
                selectedKeyframes: frames,
                selectedLayers: this.selectedLayers,
                // keyframe: this.currentKeyframe,
                keyframeLock: this.keyframeLock,
            };
        },
        disableFirstPanel() {
            return this.currentPanel === 0;
        },
        disablePrevPanel() {
            return this.currentPanel === 0;
        },
        disableNextPanel() {
            return this.currentPanel === this.episode.data.length - 1;
        },
        disableLastPanel() {
            return this.currentPanel === this.episode.data.length - 1;
        },
        readingTime() {
            let time = 0;
            if (this.episode.data[this.currentPanel].totalReadingTime) {
                time = this.episode.data[this.currentPanel].totalReadingTime;
            }
            return time;
        },
        minPanelDuration() {
            let n = this.episode.data[this.currentPanel].minDuration;
            if (!n) n = 1;
            return n;
        },
        panelDuration() {
            let delayTime = 0;
            if (this.episode.data[this.currentPanel].totalDelayTime) {
                delayTime = this.episode.data[this.currentPanel].totalDelayTime;
            }
            return Math.max(
                this.readingTime + delayTime,
                this.minPanelDuration
            );
        },
        episodeDuration() {
            let totalSeconds = 0;
            for (let panel of this.episode.data) {
                let readingTime = 0;
                if (panel.totalReadingTime) {
                    readingTime = panel.totalReadingTime;
                }

                let delayTime = 0;
                if (panel.totalDelayTime) {
                    delayTime = panel.totalDelayTime;
                }

                let transitionTime = 0;
                if (panel.transition && panel.transition.type !== "none") {
                    transitionTime = panel.transition.duration;
                }

                totalSeconds +=
                    Math.max(readingTime + delayTime, panel.minDuration) +
                    transitionTime;
            }

            return totalSeconds;

            // let delayTime = 0;
            // if (this.episode.data[this.currentPanel].totalDelayTime) {
            //     delayTime = this.episode.data[this.currentPanel].totalDelayTime;
            // }
            // return Math.max(
            //     this.readingTime + delayTime,
            //     this.minPanelDuration
            // );
        },
        transitionType() {
            if (this.episode.data[this.currentPanel].transition)
                return this.episode.data[this.currentPanel].transition.type;
            return "none";
        },
        transitionDuration() {
            if (this.episode.data[this.currentPanel].transition)
                return this.episode.data[this.currentPanel].transition.duration;
            return 1;
        },
    },
    watch: {
        currentPanel() {
            this.getDialog();
        },
        dialogInput() {
            this.setSavedFileStatus(false);
            this.updateDialog();
        },
        selectedLayers() {
            // highlight selected layer
            let panel = this.episode.data[this.currentPanel].layers;
            for (const layer of panel) {
                layer.selected = false;
            }
            for (const layerIndex of this.selectedLayers) {
                panel[layerIndex].selected = true;
            }
        },
    },
    methods: {
        ...mapMutations([
            "setEditMode",
            "setCurrentKeyframe",
            "goFirstKeyframe",
            "goLastKeyframe",
            "goFirstPanel",
            "goPrevPanel",
            "goNextPanel",
            "goToPanel",
            "goLastPanel",
            "updateTransitionDuration",
            "updateTransitionType",
        ]),
        initEditor() {
            console.log("----INIT----");
            // if (!this.episode.title === "") this.createNewEpisode();
            this.goFirstKeyframe();
        },

        // NAVIGATE

        clickFirstPanel() {
            this.editorPause();
            this.goFirstKeyframe();
            this.selectedLayers = [];
            this.goFirstPanel();
            this.getDialog();
        },
        clickPrevPanel() {
            this.editorPause();
            this.goFirstKeyframe();
            this.selectedLayers = [];
            this.goPrevPanel();
            this.getDialog();
        },
        clickNextPanel() {
            this.editorPause();
            this.goFirstKeyframe();
            this.selectedLayers = [];
            this.goNextPanel();
            this.getDialog();
        },
        clickLastPanel() {
            this.editorPause();
            this.goFirstKeyframe();
            this.selectedLayers = [];
            this.goLastPanel();
            this.getDialog();
        },
        onKeyPress(e) {
            if (document.activeElement === document.body) {
                if (e.key == ",") this.goFirstKeyframe();
                if (e.key == ".") this.goLastKeyframe();
                // arrow navigation
                // if (e.key == "ArrowLeft") this.clickPrevPanel();
                // if (e.key == "ArrowRight") this.clickNextPanel();
            }
        },
        play() {
            let rewindTimer = 0;
            if (this.currentKeyframe !== 0) {
                this.goFirstKeyframe();
                rewindTimer = 200;
            }
            setTimeout(() => {
                this.setEditMode(false);
                this.$refs.ComicPlayer.startPlaying(this.currentPanel);
            }, rewindTimer);
        },
        editorPause() {
            console.log("eee editorPause", this.currentPanel);
            // triggered internal to editor, ex: stop btn
            this.setEditMode(true);
            this.$refs.ComicPlayer.stopAndEdit();
            this.selectedLayers = [];
            this.goFirstKeyframe();
            this.getDialog();
        },
        playerPause(panel) {
            console.log("eee playerPause", panel);
            // triggered by player, ex: click layer
            this.goToPanel(panel);
            this.setEditMode(true);
            this.getDialog();
        },
        togglePlayLoopMode() {
            switch (this.playLoopMode) {
                case "episode":
                    this.playLoopMode = "panel";
                    this.playLoopModeClass = "fa-arrow-rotate-right";
                    break;
                case "panel":
                    this.playLoopMode = "scene";
                    this.playLoopModeClass = "fa-arrows-rotate";
                    break;
                case "scene":
                    this.playLoopMode = "episode";
                    this.playLoopModeClass = "fa-arrows-spin";
                    break;
            }
        },

        // EDIT
        ...mapMutations({
            updateLayerKeyframe: "updateLayerKeyframe",
            updateMinDuration: "updateMinDuration",
            setEpisode: "setEpisode",
        }),
        setSavedFileStatus(state) {
            this.$store.commit("saved", state);
        },
        createNewEpisode() {
            // this.$store.dispatch("createBlankEpisode");
            this.$store.dispatch("loadEpisode", { episode: "blank", panel: 0 });
            this.setSavedFileStatus(false);
            // this.loading = false;
        },
        insertPanelBefore() {
            const newPanel = JSON.parse(JSON.stringify(this.blankPanel));
            this.episode.data.splice(this.currentPanel, 0, newPanel);
            this.episode.panelCount = this.episode.data.length;
            this.goFirstKeyframe();
            this.selectedLayers = [];
        },
        insertPanelAfter() {
            const newPanel = JSON.parse(JSON.stringify(this.blankPanel));
            this.episode.data.splice(this.currentPanel + 1, 0, newPanel);
            this.episode.panelCount = this.episode.data.length;
            this.clickNextPanel();
        },
        duplicatePanelBefore() {
            const newPanel = JSON.parse(
                JSON.stringify(this.episode.data[this.currentPanel])
            );
            this.episode.data.splice(this.currentPanel, 0, newPanel);
            this.episode.panelCount = this.episode.data.length;
            this.goFirstKeyframe();
            this.selectedLayers = [];
        },
        duplictePanelAfter() {
            const newPanel = JSON.parse(
                JSON.stringify(this.episode.data[this.currentPanel])
            );
            this.episode.data.splice(this.currentPanel + 1, 0, newPanel);
            this.episode.panelCount = this.episode.data.length;
            this.clickNextPanel();
        },
        deleteCurrentPanel() {
            const num = this.currentPanel + 1;
            const total = this.episode.data.length;
            const msg = "Delete panel " + num + " of " + total + "?";
            if (confirm(msg) == true) {
                if (total === 1) {
                    this.initPanel();
                } else {
                    this.episode.data.splice(this.currentPanel, 1);
                }
            }
        },
        initPanel() {
            this.episode.data[this.currentPanel] = JSON.parse(
                JSON.stringify(this.blankPanel)
            );
            this.selectedLayers = [];
            this.dialogInput == "";
        },
        changeMinDuration(e) {
            let val = e.target.value;
            if (val == "") val = 1;
            this.updateMinDuration(val);
        },
        setTransitionType(e) {
            let val = e.target.value;
            if (val == "") val = "none";
            console.log("setTransitionType", val);
            this.updateTransitionType(val);
        },
        setTransitionDuration(e) {
            let val = parseFloat(e.target.value);
            if (isNaN(val)) val = 1;
            this.updateTransitionDuration(val);
        },
        getDialog() {
            let dialog = "";
            for (let line of this.episode.data[this.currentPanel].dialog) {
                if (line.delay > 0) dialog += "~" + line.delay + " ";
                if (line.top === true) dialog += "^ ";
                if (line.color !== "#ffffff") dialog += line.color + " ";
                dialog += line.text + "\n";
            }
            this.dialogInput = dialog;
        },
        updateDialog() {
            let totalReadingTime = 0;
            let totalDelayTime = 0;
            this.episode.data[this.currentPanel].dialog = [];
            for (let str of this.dialogInput.split("\n")) {
                let line = {
                    // default options
                    readingTime: 0,
                    delay: 0,
                    color: "#ffffff",
                    text: "",
                    top: false,
                };

                str = str.trim();
                let foundPrefix = null;
                let n = 0; // for safety
                do {
                    n++;
                    foundPrefix = false; // repeat until all prefixes removed
                    // delay
                    if (str.charAt(0) === "~") {
                        foundPrefix = true;
                        str = str.substring(1); // remove prefix
                        let delay = str.split(" ")[0]; // get 1st word
                        line.delay = parseFloat(delay);
                        str = this.removeFirstWord(str);
                    }
                    // text color
                    if (str.charAt(0) === "#") {
                        const color = str.split(" ")[0]; // get 1st word
                        const reg = /^#([0-9a-f]{3}){1,2}$/i; // validate hex
                        if (reg.test(color)) {
                            foundPrefix = true;
                            line.color = color;
                            str = this.removeFirstWord(str); // remove color
                        }
                    }
                    // top text
                    if (str.charAt(0) === "^") {
                        // console.log("top");
                        foundPrefix = true;
                        line.top = true;
                        str = str.substring(1).trim();
                    }
                    line.text = str.trim();
                } while (foundPrefix === true && n < 10);
                if (line.text !== "" || line.delay > 0) {
                    let readingTime = Math.max(
                        line.text.length / this.readingWordsPerMin,
                        this.minReadTime
                    );
                    readingTime = Math.round(readingTime * 10) / 10; // 1 decimal
                    line.readingTime = readingTime;
                    totalReadingTime += readingTime;
                    totalDelayTime += line.delay;
                    this.episode.data[this.currentPanel].dialog.push(line);
                }
            }
            this.episode.data[this.currentPanel].totalReadingTime =
                Math.round(totalReadingTime * 10) / 10;
            this.episode.data[this.currentPanel].totalDelayTime =
                Math.round(totalDelayTime * 10) / 10;
        },
        removeFirstWord(str) {
            const indexOfSpace = str.indexOf(" ");
            if (indexOfSpace === -1) {
                return "";
            }
            return str.substring(indexOfSpace + 1).trim();
        },
        timecode(seconds) {
            if (seconds > 0)
                return new Date(seconds * 1000).toISOString().substr(14, 5);
            return "00:00";
        },
        importLocalFiles(event) {
            this.setSavedFileStatus(false);
            this.keyframeLock = true;
            const files = event.target.files;
            for (const file of files) {
                console.log(file);
                const pict = {
                    name: file.name,
                    selected: false,
                    height: 0,
                    width: 0,
                    frame: [
                        {
                            x: 0,
                            y: 0,
                            scale: 1.0,
                            opacity: 1,
                            rotate: 0.0,
                            blur: 0,
                            delay: 0,
                        },
                        {
                            x: 0,
                            y: 0,
                            scale: 1.0,
                            opacity: 1,
                            rotate: 0.0,
                            blur: 0,
                            delay: 0,
                        },
                    ],
                };
                this.episode.data[this.currentPanel].layers.push(pict);
            }
            const n = this.episode.data[this.currentPanel].layers.length - 1;
            if (n > -1) {
                let newSelectedLayers = [];
                newSelectedLayers.push(n);
                this.selectedLayers = newSelectedLayers;
            }
        },
        deleteLayer() {
            this.setSavedFileStatus(false);
            let toRemove = this.selectedLayers;
            for (const i of toRemove) {
                console.log("delete:", i);
                if (i > -1) {
                    this.episode.data[this.currentPanel].layers.splice(i, 1);
                }
            }
            this.selectedLayers = [];
        },
        moveLayerUp() {
            this.setSavedFileStatus(false);
            let sortedLayers = this.episode.data[this.currentPanel].layers;
            let newSelectedLayers = [];
            for (const i of this.selectedLayers) {
                // console.log("start:", i);
                if (i === this.layers.length - 1) {
                    break;
                }
                // console.log("move:", i, i + 1);
                newSelectedLayers.push(i + 1);
                let temp = sortedLayers[i];
                sortedLayers.splice(i, 1);
                sortedLayers.splice(i + 1, 0, temp);
            }
            if (newSelectedLayers !== [])
                this.selectedLayers = newSelectedLayers;
            this.episode.data[this.currentPanel].layers = sortedLayers;
        },
        moveLayerDown() {
            this.setSavedFileStatus(false);
            let sortedLayers = this.episode.data[this.currentPanel].layers;
            let newSelectedLayers = [];
            let shouldSkip = false;
            this.selectedLayers
                .slice()
                .reverse()
                .forEach(function (i) {
                    // console.log("start:", i);
                    if (i === 0) {
                        shouldSkip = true;
                    }
                    if (!shouldSkip) {
                        // console.log("move:", i, i - 1);
                        newSelectedLayers.push(i - 1);
                        let temp = sortedLayers[i];
                        sortedLayers.splice(i, 1);
                        sortedLayers.splice(i - 1, 0, temp);
                    }
                });
            if (newSelectedLayers !== []) {
                this.selectedLayers = newSelectedLayers;
            }
            this.episode.data[this.currentPanel].layers = sortedLayers;
        },
        clickComicLayer({ i, shiftKey }) {
            // console.log("shiftKey:", shiftKey);
            let pict = this.layers[i];
            pict.selected = !pict.selected;
            if (pict.selected) {
                if (shiftKey) {
                    // add to selection
                    this.selectedLayers.push(i);
                    this.selectedLayers = this.selectedLayers.sort();
                } else {
                    // select only one
                    this.selectedLayers = [i];
                }
            } else {
                // deselect and update list
                let newSelection = [];
                for (const [i, v] of this.layers.entries()) {
                    if (v.selected) newSelection.push(i);
                }
                this.selectedLayers = newSelection;
            }
        },
        copyStartFrameToEnd() {
            const msg = "Copy all Start keyframe values to End keyframe?";
            if (confirm(msg) == true) {
                for (const i of this.selectedLayers) {
                    let layer = this.episode.data[this.currentPanel].layers[i];
                    layer.frame[1] = Object.assign({}, layer.frame[0]);
                }
            }
        },
        setLayerProp(id, val) {
            // update all selected layers
            if (val === null || val === "") {
                if (id === "scale" || id === "opacity") val = 1;
                else val = 0;
            }
            // console.log(">>>>> setLayerProp", "id", id, "val", val);
            const keyframeCount = this.editorState.selectedKeyframes[0].length;
            for (const layer of this.editorState.selectedLayers) {
                // run twice if keyframe locked
                for (let i = 0; i <= keyframeCount - 1; i++) {
                    if (
                        this.editorState.keyframeLock ||
                        this.currentKeyframe == i
                    ) {
                        let data = {
                            layer: layer,
                            keyframe: i,
                            id: id,
                            val: val,
                        };
                        this.updateLayerKeyframe(data);
                    }
                }
            }
        },
        alignLayer(n) {
            this.setSavedFileStatus(false);
            for (const i of this.selectedLayers) {
                let layer = this.episode.data[this.currentPanel].layers[i];
                if (n === 1 || n === 2 || n === 3) {
                    // align top
                    this.setLayerProp("y", 0);
                }
                if (n === 7 || n === 8 || n === 9) {
                    // align bottom
                    this.setLayerProp("y", this.stage.height - layer.height);
                }
                if (n === 1 || n === 4 || n === 7) {
                    // align left
                    this.setLayerProp("x", 0);
                }
                if (n === 3 || n === 6 || n === 9) {
                    // align right
                    this.setLayerProp("x", this.stage.width - layer.width);
                }
                if (n === 5) {
                    // align center
                    this.setLayerProp(
                        "x",
                        Math.round((this.stage.width - layer.width) / 2)
                    );
                    this.setLayerProp(
                        "y",
                        Math.round((this.stage.height - layer.height) / 2)
                    );
                }
            }
        },
        importEpisodeFile(e) {
            // console.log('importEpisodeFile', e);
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            let file = files[0];
            let reader = new FileReader();
            reader.onload = (e) => {
                // console.log(e.target.result);
                let episode = JSON.parse(e.target.result);
                this.setEpisode(episode);
                // this.loading = false;
            };
            reader.readAsText(file);
        },
        saveEpisodeFile() {
            this.setSavedFileStatus(true);
            const data = JSON.stringify(this.episode, null, 4);
            const blob = new Blob([data], { type: "text/plain" });
            const a = document.createElement("a");
            a.download = this.episode.number + ".json";
            a.href = URL.createObjectURL(blob);
            a.dataset.downloadurl = [
                "text/json",
                a.download,
                a.href,
                a.target,
            ].join(":");
            a.addEventListener("click", () => {
                setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
            });
            a.click();
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/editor.scss";
</style>

<style lang="scss">
#editor #comic img {
    user-select: none;
    outline: none;
    outline-offset: -4px;

    &:hover {
        outline: 4px solid rgba(255, 255, 255, 0.15);
    }

    &.is-selected {
        /* box-shadow: inset 0 0 3px 3px cyan; */
        outline: 4px solid rgba(0, 255, 255, 0.66);
        border-radius: 12px;

        &:hover {
            outline: 4px solid rgba(0, 255, 255, 0.8);
        }
    }
}
</style>

<!-- <div class="modal" :class="{ 'is-active': showImportImageModal }">
            <div
                @click="showImportImageModal = false"
                class="modal-background"
            ></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">
                        Import from
                        <strong>episodes/assets/{{ episode.number }}</strong>
                    </p>
                    <button
                        @click="showImportImageModal = false"
                        class="delete"
                        aria-label="close"
                    ></button>
                </header>
                <section class="modal-card-body" style="column-width: 200px">
                    <template v-if="filteredImageFiles.length > 0">
                        <div
                            v-for="(file, index) in filteredImageFiles"
                            :key="index"
                        >
                            <label class="checkbox">
                                <input
                                    v-model="selectedImageFiles"
                                    :id="index"
                                    :value="file"
                                    type="checkbox"
                                />
                                {{ file }}
                            </label>
                        </div>
                    </template>
                    <template v-else>
                        <p v-if="imageFilesQuery === ''">
                            <em
                                >No files in the episode
                                {{ episode.number }} folder.</em
                            >
                        </p>
                        <p>
                            <em
                                >No matches. <br />
                                Try
                                <a @click="imageFilesQuery = ''"
                                    >clearing the search field</a
                                >.</em
                            >
                        </p>
                    </template>
                </section>
                <footer class="modal-card-foot has-options">
                    <div>
                        <div class="field has-addons">
                            <div class="control">
                                <input
                                    v-model="imageFilesQuery"
                                    class="input"
                                    type="text"
                                    placeholder="Search"
                                />
                            </div>
                            <div class="control">
                                <button
                                    @click="imageFilesQuery = ''"
                                    class="button"
                                >
                                    <span class="icon"
                                        ><i class="fa-solid fa-xmark"></i
                                    ></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="buttons">
                        <button @click="selectedImageFiles = []" class="button">
                            Reset
                        </button>
                        <button
                            @click="showImportImageModal = false"
                            class="button"
                        >
                            Cancel
                        </button>

                        <button
                            @click="importImage"
                            class="button is-primary"
                            :disabled="selectedImageFiles.length < 1"
                        >
                            Import
                            <span v-if="selectedImageFiles.length > 1"
                                >&nbsp; {{ selectedImageFiles.length }}</span
                            >
                        </button>
                    </div>
                </footer>
            </div>
        </div> -->


