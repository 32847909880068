<template>
    <div class="align-button-grid">
        <div class="buttons are-small">
            <button @click="align(1)" title="top left" class="button is-dark">
                <span class="icon">
                    <i
                        class="fa-solid fa-angle-left fa-rotate-by"
                        style="--fa-rotate-angle: 45deg"
                    >
                    </i>
                </span>
            </button>
            <button @click="align(2)" title="top" class="button is-dark">
                <span class="icon">
                    <i class="fa-solid fa-minus"></i>
                </span>
            </button>
            <button @click="align(3)" title="top right" class="button is-dark">
                <span class="icon">
                    <i
                        class="fa-solid fa-angle-right fa-rotate-by"
                        style="--fa-rotate-angle: -45deg"
                    ></i>
                </span>
            </button>
        </div>

        <div class="buttons are-small">
            <button @click="align(4)" title="left" class="button is-dark">
                <span class="icon">
                    <i class="fa-solid fa-minus fa-rotate-90"></i>
                </span>
            </button>
            <button @click="align(5)" title="center" class="button is-dark">
                <span class="icon">
                    <i class="fa-solid fa-plus"></i>
                </span>
            </button>
            <button @click="align(6)" title="right" class="button is-dark">
                <span class="icon">
                    <i class="fa-solid fa-minus fa-rotate-90"></i>
                </span>
            </button>
        </div>

        <div class="buttons are-small">
            <button @click="align(7)" title="bottom left" class="button is-dark">
                <span class="icon">
                    <i
                        class="fa-solid fa-angle-left fa-rotate-by"
                        style="--fa-rotate-angle: -45deg"
                    >
                    </i>
                </span>
            </button>
            <button @click="align(8)" title="bottom" class="button is-dark">
                <span class="icon">
                    <i class="fa-solid fa-minus"></i>
                </span>
            </button>
            <button @click="align(9)" title="bottom right" class="button is-dark">
                <span class="icon">
                    <i
                        class="fa-solid fa-angle-right fa-rotate-by"
                        style="--fa-rotate-angle: 45deg"
                    ></i>
                </span>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "AlignmentGrid",
    components: {},
    data() {
        return {};
    },
    methods: {
        align(num) {
            this.$emit("align", num);
        },
    },
};
</script>
<style lang="scss" scoped>
.align-button-grid {
    .buttons {
        margin: 0;
        button.button {
            margin: 1px;
            width: 30%;
            height: 1.5rem;
        }
    }
}
</style>